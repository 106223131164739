import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockIcon from "@material-ui/icons/Lock";
import StepLabel from "@material-ui/core/StepLabel";

import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
// import ReactSelect from "../../common/ReactSelect";
import RadioGroup from "../../common/RadioGroup";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
import HelpLabel from "../../common/HelpLabel";
// import { createSelector } from "../../common/orm";
import states from "../../common/statesWoTerritories.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import {
    ApplicationRecord,
    ApplicationRecordStatus,
    ColumbusBlue,
    ColumbusBlueContact,
    ColumbusBlueAttachment
} from "../../page/models"; 

const styles = theme => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2
    },
    centerAlign: {
        textAlign: "center"
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    rightAlign: {
        textAlign: "right"
    },
    flex: {
        flex: 1
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8
    },
    dialogMin: {
        minWidth: 400
    },
    minWidth: {
        minWidth: 600
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
    height: {
        height: "600px",
        width: "100%"
    },
    btnroot: {
        color: "#9e9e9e",
    },
});

function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row,
        value: row
    }));
}

// const getApplication = createSelector(
//     (state, ownProps) => ownProps.match.params["hash"],
//     (session, hash) => {
//         return session.ColumbusBlue.filter(p => p.hash === hash).toModelArray()[0];
//     }
// );

class ColumbusBlueApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            contactDialogOpen: false,
            contactEditing: null,
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: null,
            applicant_same_NO: true,
            // applicant_same_secondary_NO: true,
            permittee_same_NO: true,
            permittee_same_secondary_NO: true,
            loaded_record: null
        };
    }

    componentDidMount() {
        const {
            match,
            ormColumbusBlueLoadDetailChild,
            ormColumbusBlueContactLoadDetailChild,
            ormColumbusBlueAttachmentLoadDetailChild
        } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormColumbusBlueLoadDetailChild(match.params.hash, function(rec) {
                component.setState({ loaded_record: rec[0], mainFormKey: window.performance.now(), applicant_same_NO: !rec[0].applicant_same,  
                    permittee_same_NO: !rec[0].permittee_same, permittee_same_secondary_NO: !rec[0].permittee_same_secondary  });
            });
            ormColumbusBlueContactLoadDetailChild(match.params.hash, function(data) {
                component.setState({ temp_contacts: data });
            });
            ormColumbusBlueAttachmentLoadDetailChild(match.params.hash, function(data) {
                component.setState({ temp_attachments: data });
            });
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = values => {
        const {temp_attachments, loaded_record} = this.state;

        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };

      

        var valObj = {
            owner_first_name: isRequired(values.owner_first_name),
            owner_last_name: isRequired(values.owner_last_name), 
            owner_mailing_address: isRequired(values.owner_mailing_address),
            owner_phone: isRequired(values.owner_phone),
            owner_city: isRequired(values.owner_city),
            owner_state: isRequired(values.owner_state),
            owner_zip: isRequired(values.owner_zip),
            owner_email: validateEmail(values.owner_email),
            secondary_first_name: isRequired(values.secondary_first_name),
            secondary_last_name: isRequired(values.secondary_last_name),
            secondary_organization: isRequired(values.secondary_organization),
            secondary_mailing_address: isRequired(values.secondary_mailing_address),
            secondary_city: isRequired(values.secondary_city),
            secondary_state: isRequired(values.secondary_state),
            secondary_zip: isRequired(values.secondary_zip),
            secondary_phone: isRequired(values.secondary_phone),
            secondary_email: validateEmail(values.secondary_email),
            // applicant_type: isRequired(values.applicant_type),
            location_description: isRequired(values.location_description),
            //3
            project_name: isRequired(values.project_name),
            project_type: isRequired(values.project_type),
            project_description: isRequired(values.project_description),
            size: isRequired(values.size),
            disturbed_area: isRequired(values.disturbed_area),
            area_proposed_impervious: isRequired(values.area_proposed_impervious),
            proposed_stormwater: isRequired(values.proposed_stormwater),
            //4
            im_receiving_waterbody: values.im_receiving_waterbody !== values.receiving_waterbody ? isRequired(values.im_receiving_waterbody) : "Cant Match Receiving Waterbody (Step 5)",
            im_outstanding_waterbody: isRequired(values.im_outstanding_waterbody),
            im_303_list: isRequired(values.im_303_list),
            im_what_impairments: values.im_303_list === 'Yes'? isRequired(values.im_what_impairments): null,
            im_tmdl: isRequired(values.im_tmdl),
            //5
            receiving_waterbody: values.im_receiving_waterbody !== values.receiving_waterbody ? isRequired(values.receiving_waterbody) : "Cant Match Immediate Receiving Waterbody (Step 4)",
            outstanding_waterbody: isRequired(values.outstanding_waterbody),
            re_303_list: isRequired(values.re_303_list),
            what_impairments: values.re_303_list === 'Yes'? isRequired(values.what_impairments): null,
            tmdl: isRequired(values.tmdl),
            //6
            within_floodplain: isRequired(values.within_floodplain),
            within_protection: isRequired(values.within_protection),
            is_wetland: isRequired(values.is_wetland),
            conveyance: isRequired(values.conveyance),
            groupc_d: isRequired(values.groupc_d),
            legal_drain: isRequired(values.legal_drain),
            contaminated: isRequired(values.contaminated),
            impacted_drainage: isRequired(values.impacted_drainage),
            erosion: isRequired(values.erosion),

            signature: isRequired(values.signature),
            // signature2: isRequired(values.signature2),
            // signature3: isRequired(values.signature3),
            // signature4: isRequired(values.signature4),
            // signature5: isRequired(values.signature5),
            // signature6: isRequired(values.signature6),
            // signature7: isRequired(values.signature7)
        };
        if (!values.applicant_same && !values.applicant_same_secondary) {
            valObj["applicant_first_name"] = isRequired(values.applicant_first_name);
            valObj["applicant_last_name"] = isRequired(values.applicant_last_name); 
            valObj["applicant_mailing_address"] = isRequired(values.applicant_mailing_address);
            valObj["applicant_city"] = isRequired(values.applicant_city);
            valObj["applicant_state"] = isRequired(values.applicant_state);
            valObj["applicant_zip"] = isRequired(values.applicant_zip);
            valObj["applicant_phone"] = isRequired(values.applicant_phone);
            valObj["applicant_email"] = isRequired(values.applicant_email);
        }else{//clear them off else react form if memo wont submit on the second go round
            valObj["applicant_first_name"] = null
            valObj["applicant_last_name"] = null
            valObj["applicant_mailing_address"] = null
            valObj["applicant_city"] = null
            valObj["applicant_state"] = null
            valObj["applicant_zip"] = null
            valObj["applicant_phone"] = null
            valObj["applicant_email"] = null
        }

        if (!values.permittee_same && !values.permittee_same_secondary) {
            valObj["permittee_first_name"] = isRequired(values.permittee_first_name);
            valObj["permittee_last_name"] = isRequired(values.permittee_last_name); 
            valObj["permittee_mailing_address"] = isRequired(values.permittee_mailing_address);
            valObj["permittee_city"] = isRequired(values.permittee_city);
            valObj["permittee_state"] = isRequired(values.permittee_state);
            valObj["permittee_zip"] = isRequired(values.permittee_zip);
            valObj["permittee_phone"] = isRequired(values.permittee_phone);
            valObj["permittee_email"] = isRequired(values.permittee_email);
        }else{ //clear them off else react form if memo wont submit on the second go round
            valObj["permittee_first_name"] = null
            valObj["permittee_last_name"] = null
            valObj["permittee_mailing_address"] = null
            valObj["permittee_city"] = null
            valObj["permittee_state"] = null
            valObj["permittee_zip"] = null
            valObj["permittee_phone"] = null;
            valObj["permittee_email"] = null
            
        }

        var alerts = "";
        var ai = 4;
        if (valObj["owner_first_name"] === "Required") {
            alerts += "Property Owner First Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_last_name"] === "Required") {
            alerts += "Property Owner Last Name (Step 1)\n";
            ai = 0;
        } 
        if (valObj["owner_mailing_address"] === "Required") {
            alerts += "Property Owner Mailing Address (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_city"] === "Required") {
            alerts += "Property Owner City (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_state"] === "Required") {
            alerts += "Property Owner State (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_zip"] === "Required") {
            alerts += "Property Owner Zip (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_phone"] === "Required") {
            alerts += "Property Owner Phone (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_email"] === "Invalid Email Address") {
            alerts += "Property Owner Email (Step 1)\n";
            ai = 0;
        }

        if (valObj["secondary_first_name"] === "Required") {
            alerts += "Plan Preparer First Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_last_name"] === "Required") {
            alerts += "Plan Preparer Last Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_organization"] === "Required") {
            alerts += "Plan Preparer Organization / Company (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_mailing_address"] === "Required") {
            alerts += "Plan Preparer Mailing Address (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_city"] === "Required") {
            alerts += "Plan Preparer City (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_state"] === "Required") {
            alerts += "Plan Preparer State (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_zip"] === "Required") {
            alerts += "Plan Preparer Zip (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_phone"] === "Required") {
            alerts += "Plan Preparer Phone (Step 1)\n";
            ai = 0;
        }
        if (valObj["secondary_email"] === "Invalid Email Address") {
            alerts += "Plan Preparer Email (Step 1)\n";
            ai = 0;
        }
        // if (valObj["applicant_type"] === "Required") {
        //     alerts += "Applicant Role (Step 1)\n";
        //     ai = 0;
        // }
        if (!values.applicant_same && !values.applicant_same_secondary) {
            if (valObj["applicant_first_name"] === "Required") {
                alerts += "Application Representative First Name (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_last_name"] === "Required") {
                alerts += "Application Representative Last Name (Step 1)\n";
                ai = 0;
            } 
            if (valObj["applicant_mailing_address"] === "Required") {
                alerts += "Application Representative Mailing Address (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_city"] === "Required") {
                alerts += "Application Representative City (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_state"] === "Required") {
                alerts += "Application Representative State (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_zip"] === "Required") {
                alerts += "Application Representative Zip (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_phone"] === "Required") {
                alerts += "Application Representative Phone (Step 1)\n";
                ai = 0;
            }
            if (valObj["applicant_email"] === "Invalid Email Address") {
                alerts += "Application Representative Email (Step 1)\n";
                ai = 0;
            }
        }
        if (!values.permittee_same && !values.permittee_same_secondary) {
            if (valObj["permittee_first_name"] === "Required") {
                alerts += "Permittee First Name (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_last_name"] === "Required") {
                alerts += "Permittee Last Name (Step 1)\n";
                ai = 0;
            } 
            if (valObj["permittee_mailing_address"] === "Required") {
                alerts += "Permittee Mailing Address (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_city"] === "Required") {
                alerts += "Permittee City (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_state"] === "Required") {
                alerts += "Permittee State (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_zip"] === "Required") {
                alerts += "Permittee Zip (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_phone"] === "Required") {
                alerts += "Permittee Phone (Step 1)\n";
                ai = 0;
            }
            if (valObj["permittee_email"] === "Invalid Email Address") {
                alerts += "Permittee Email (Step 1)\n";
                ai = 0;
            }
        }
        if (valObj["location_description"] === "Required") {
            alerts += "Project Location Description (Step 2)\n";
            ai = 1;
        }
        //3
        if (valObj["project_name"] === "Required") {
            alerts += "Project Name (Step 3)\n";
            ai = 2;
        }
        if (valObj["project_type"] === "Required") {
            alerts += "Project Type (Step 3)\n";
            ai = 2;
        }
        if (valObj["project_description"] === "Required") {
            alerts += "Project Narrative Description (Step 3)\n";
            ai = 2;
        } 
        if (valObj["size"] === "Required") {
            alerts += "Project Acerage Total (Step 3)\n";
            ai = 1;
        }
        if (valObj["disturbed_area"] === "Required") {
            alerts += "Project Total Disturbed Area (Step 3)\n";
            ai = 2;
        }
        if (valObj["area_proposed_impervious"] === "Required") {
            alerts += "Proposed Impervious Area (Step 3)\n";
            ai = 2;
        }
        if (valObj["proposed_stormwater"] === "Required") {
            alerts += "Proposed Stormwater Management Facilities (Step 3)\n";
            ai = 2;
        }
        //4
        if (valObj["im_receiving_waterbody"] === "Required") {
            alerts += "Immediate Receiving Waterbody (Step 4)\n";
            ai = 3;
        }
        if (valObj["im_receiving_waterbody"] === "Cant Match Receiving Waterbody (Step 5)") {
            alerts += "Immediate Receiving Waterbody (Step 4) cant match Receiving Waterbody (Step 5)\n";
            ai = 3;
        }
        if (valObj["im_outstanding_waterbody"] === "Required") {
            alerts += "Is this listed as an Outstanding waterbody? (Step 4)\n";
            ai = 3;
        }
        if (valObj["im_303_list"] === "Required") {
            alerts += "Is this listed on the 303d List? (Step 4)\n";
            ai = 3;
        }
        if (valObj["im_what_impairments"] === "Required") {
            alerts += "What immediate impairments? (Step 4)\n";
            ai = 3;
        }
        if (valObj["im_tmdl"] === "Required") {
            alerts += "Is there a TMDL? (Step 4)\n";
            ai = 3;
        }
        //5
        if (valObj["receiving_waterbody"] === "Required") {
            alerts += "Receiving Waterbody (Step 5)\n";
            ai = 4;
        }
        if (valObj["receiving_waterbody"] === "Cant Match Immediate Receiving Waterbody (Step 4)") {
            alerts += "Receiving Waterbody (Step 5) cant match Immediate Receiving Waterbody (Step 4)\n";
            ai = 4;
        }
        if (valObj["outstanding_waterbody"] === "Required") {
            alerts += "Is this listed as an Outstanding waterbody? (Step 5)\n";
            ai = 4;
        }
        if (valObj["re_303_list"] === "Required") {
            alerts += "Is this listed on the 303d List? (Step 5)\n";
            ai = 4;
        }
        if (valObj["what_impairments"] === "Required") {
            alerts += "What receiving impairments? (Step 5)\n";
            ai = 3;
        }
        if (valObj["tmdl"] === "Required") {
            alerts += "Is there a TMDL? (Step 5)\n";
            ai = 4;
        }
        //6
        if (valObj["within_floodplain"] === "Required") {
            alerts += "Is the project within a floodplain?  (Step 6)\n";
            ai = 5;
        }
        if (valObj["within_protection"] === "Required") {
            alerts += "Is the project within a wellhead protection area? (Step 6)\n";
            ai = 5;
        }
        if (valObj["is_wetland"] === "Required") {
            alerts += "Is there a wetland on or adjacent to the project? (Step 6)\n";
            ai = 5;
        }
        if (valObj["conveyance"] === "Required") {
            alerts += "Is there a conveyance, natural or manmade, on or adjacent to the project?  (Step 6)\n";
            ai = 5;
        }
        if (valObj["groupc_d"] === "Required") {
            alerts += "Is project located within or adjacent to areas containing soils of hydrologic soil group C or D? (Step 6)\n";
            ai = 5;
        }
        if (valObj["legal_drain"] === "Required") {
            alerts += "Is there a legal drain on or adjacent to the project?(Step 6)\n";
            ai = 5;
        }
        if (valObj["contaminated"] === "Required") {
            alerts += "Is project located within or adjacent to areas that have been determined to be in a contaminated area? (Step 6)\n";
            ai = 5;
        }
        if (valObj["impacted_drainage"] === "Required") {
            alerts += "Is project located within or adjacent to impacted drainage areas? (Step 6)\n";
            ai = 5;
        }
        if (valObj["erosion"] === "Required") {
            alerts += "Is the project located within or discharging to an area with known erosion, drainage, or flooding problems? (Step 6)\n";
            ai = 5;
        }

       
        if(loaded_record && loaded_record.apprec.status === "Saved - Pending Payment Verification"){
            var attachmentsNeeded = ['Signed Application', 'Receipt for Application Fee']
         
            temp_attachments.forEach(at=>{
                if(attachmentsNeeded.find(a=>a===at._type)){
                    attachmentsNeeded = attachmentsNeeded.filter(af=> af!==at._type? af:null)
                }
            })
            if(attachmentsNeeded.length > 0){
                attachmentsNeeded.forEach(at=>{
                // let t = {}
                // t[at]="Required"
                // valObj = Object.assign(valObj, t)
                alerts += `${at} File Type Required (Step 7)\n`;
                ai = 6
                
                })
            }
            
            valObj['attachment'] = attachmentsNeeded.length < 1 ? '' : 'Required'
        }
        

        //8
        if (valObj["signature"] === "Required") {
            alerts += "Applicant Signature (Step 8)\n";
            ai = 7;
        }
        // if (valObj["signature2"] === "Required") {
        //     alerts += "Applicant Acknowledgement 1 (Step 8)\n";
        //     ai = 7;
        // }
        // if (valObj["signature3"] === "Required") {
        //     alerts += "Applicant Acknowledgement 2 (Step 8)\n";
        //     ai = 7;
        // }
        // if (valObj["signature4"] === "Required") {
        //     alerts += "Applicant Acknowledgement 3 (Step 8)\n";
        //     ai = 7;
        // }
        // if (valObj["signature5"] === "Required") {
        //     alerts += "Applicant Acknowledgement 4 (Step 8)\n";
        //     ai = 7;
        // }
        // if (valObj["signature6"] === "Required") {
        //     alerts += "Payment Verification (Step 8)\n";
        //     ai = 7;
        // }
        // if (valObj["signature7"] === "Required") {
        //     alerts += "Payment Verification (Step 8)\n";
        //     ai = 7;
        // }
        if (alerts) {
            alert("You are missing the following required fields and/or files:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorContact = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        var valObj = {
            first_name: isRequired(values.first_name),
            last_name: isRequired(values.last_name),
            mailing_address: isRequired(values.mailing_address),
            city: isRequired(values.city),
            state: isRequired(values.state),
            zip: isRequired(values.zip),
            phone: isRequired(values.phone),
            email: validateEmail(values.email),
            type: isRequired(values.type)
        };

        return valObj;
    };

    errorValidatorAttachment = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
            _type: isRequired(values._type)
        };

        return valObj;
    };

    formatDate = date_string => {
        if (!date_string)
            return new Date().toLocaleDateString();
        const d = new Date(date_string + " 12:00:00");
        return d.toLocaleDateString();
    };

    submitApplication = values => {
        const {
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormColumbusBlueCreate,
            ormColumbusBlueContactCreate,
            ormColumbusBlueAttachmentCreate,
            ormColumbusBlueAppUpdate,
            // ormColumbusBlueContactUpdate
        } = this.props;
        const { temp_contacts, temp_attachments, geom, loaded_record } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // uploading payment or additional docs
        if (loaded_record) {
             
            ormApplicationRecordStatusCreate({
                applicationrecord: loaded_record.apprec.id,
                status: loaded_record.apprec.status === "Saved - Pending Payment Verification"? "Received": "Resubmittal Received" //received is after they upload payment info, we then create internal record
            }).then(bla=>{
                ormColumbusBlueAppUpdate({
                    id: loaded_record.id,
                    ...values
                }).then((wait)=>{
                    let add = 0 
                    let total = temp_attachments.length + temp_contacts.length  
                    temp_contacts.forEach(function(tc) {
                        if (tc.id) {
                            // ormColumbusBlueContactUpdate({
                            //     id: tc.id,
                            //     ...tc
                            // });
                            total = total - 1
                        } else { 
                            ormColumbusBlueContactCreate({
                                columbusblue_id: loaded_record.id,
                                when_submitted: 'later',
                                ...tc
                            }).then(_z => {
                                add++;
                                if (total === add) _this.setState({ loading: false, new_save2: true });
                            });
                        }
                    });
                    temp_attachments.forEach(function(tc) {
                        if (tc.id) {
                            /*ormColumbusApplicationAttachmentUpdate({
                                id: tc.id,
                                ...tc
                            });*/
                            total = total - 1
                        } else { 
                            ormColumbusBlueAttachmentCreate({
                                columbusblue_id: loaded_record.id,
                                ...tc
                            }).then(_z => {
                                add++;
                                if (total === add) _this.setState({ loading: false, new_save2: true });
                            });
                        }
                    });
                    if (total === add) {
                        // No attachments or contacts - its finished loading
                        _this.setState({ loading: false, new_save2: true });
                    } 
                }); 
            }) 
            
        } else {
            // New
            ormApplicationRecordCreate({
                application: 2
            }).then(ar => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Saved - Pending Payment Verification"
                }).then(bla=>{ 
                    ormColumbusBlueCreate({
                        applicationrecord: ar.id,
                        geometry: geom,
                        ...values
                    }).then(col => {
                        var i = 0;
                        var j = 0; 
                        temp_contacts.forEach(function(tc) {
                            i++;
                            ormColumbusBlueContactCreate({
                                columbusblue_id: col.id,
                                when_submitted:'initial',
                                ...tc
                            }).then(_z => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        temp_attachments.forEach(function(tc) {
                            i++;
                            ormColumbusBlueAttachmentCreate({
                                columbusblue_id: col.id,
                                ...tc
                            }).then(_z => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        if (i === 0) {
                            // No attachments or contacts - its finished loading
                            _this.setState({ loading: false, new_save: true });
                        }
                    });
                })
            });
        }
    };

    addContact = values => {
        var { contactEditing, temp_contacts } = this.state;

        if (!contactEditing) {
            temp_contacts.push(values);
            this.setState({ temp_contacts: temp_contacts });
        } else {
            var temp = temp_contacts.filter(tc => tc !== contactEditing);
            temp.push(values);
            this.setState({ temp_contacts: temp });
        }

        this.setState({ contactDialogOpen: false });
    };

    addAttachment = values => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter(tc => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_contacts.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_contacts.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = index => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
            window.scroll(0,0)
        } else if (activeStep < 7){
            this.setState({ activeStep: activeStep + 1 });
            window.scroll(0,0)
        } 
        else {
            this.formApi.submitForm();
        }
    };
    
    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    }

    //this is triggered from basemapcontrolapplication.js
    setDrawingFeature = (geom) =>{ 
        if(geom){
            this.formApi.setValue('geometry', geom)
            this.formApi.setValue('latitude', geom.coordinates[1]) 
            this.formApi.setValue('longitude', geom.coordinates[0])  
        }
    }

    render() {
        const { classes/*application*/} = this.props;
        const {
            activeStep,
            contactDialogOpen,
            contactEditing,
            formKey,
            temp_contacts,
            order,
            orderBy,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            applicant_same_NO,
            // applicant_same_secondary_NO,
            permittee_same_NO,
            permittee_same_secondary_NO, 
            dialogLoading,
            loaded_record

        } = this.state;

        //this has all parameters for the basemapcontrolapplication
        var layers = [
            {attribution: "", city_id: 2,city_label: "Columbus",id:2, is_basemap: false,
            layer_type: "Web Map", layernumbers: "3", layerurl: null,
            name: "Columbus Area",on_by_default: true,opacity: 1,order: 2,proxyurl: "",show_popup: false, subdomains: "",
            tileddynamic: "Feature Service",token: "",webmap_id: "", wmslayers: "", x: -85.920951, y: 39.200326,
            geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:12 }]
        
   

        var columnDataContacts = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "type", numeric: false, label: "Type", allowSort: true },
            { id: "first_name", numeric: false, label: "First Name", allowSort: true },
            { id: "last_name", numeric: false, label: "Last Name", allowSort: true },
            { id: "mailing_address", numeric: false, label: "Address", allowSort: true },
            { id: "phone", numeric: false, label: "Phone", allowSort: true },
            { id: "email", numeric: false, label: "Email", allowSort: true }
        ];

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "date_uploaded", numeric: false, label: "Date Added", allowSort: true, date: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true }
        ];

        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "11" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>
                                This is a confirmation that the permit application has been saved and submitted successfully. A confirmation email has
                                been emailed to the <u>Representative's</u> email address with additional information to pay the application fee and sign the signature page. 
                                Please check your spam and junk folders if you have not received it or contact the city.  
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "11" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        let is_read_only = false;  
        let step_array = [
            "Contact Info",
            "Project Location",
            "Project Information and Details",
            "Immediate Receiving Waterbody",
            "Receiving Waterbody",
            "Sensitive and Critical Area Information",
            "Attach Documents",
            "Review and Submit"
            ]
        if (loaded_record && loaded_record.apprec && loaded_record.apprec.status){
            step_array = [
                "Contact Info Locked",
                "Project Location Locked",
                "Project Information and Details Locked",
                "Immediate Receiving Waterbody Locked" ,
                "Receiving Waterbody Locked",
                "Sensitive and Critical Area Information Locked",
                "Attach Documents",
                "Review and Submit Locked"
                ]
            is_read_only = true
        }
        
        
        return (
            <AppContainerApplication
                city_id="11"
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={el => (this.formApi = el)}
                    defaultValues={loaded_record?loaded_record:{'im_outstanding_waterbody': "", 'im_303_list': "",'im_what_impairments': " ",'im_tmdl': "",
                        'outstanding_waterbody': "", 're_303_list': "",'what_impairments': "",'tmdl': "", 'latitude':'', 'longitude':""}}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={values => this.errorValidator(values)}
                    onSubmit={values => this.submitApplication(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            {loaded_record && loaded_record.apprec && loaded_record.apprec.status && (
                                <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Permit Status: {loaded_record && loaded_record.apprec.status}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                    <Typography className={classes.centerAlign} variant="h6">
                                        {'Permit Application Form'}
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                      <u><b>For Properties {'>='} .5 Acres</b></u>
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                        <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be saved
                                        so please plan accordingly.
                                    </Typography>
                                    <Typography variant="caption" className={classes.centerAlign2}>
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                            {step_array.map((label, index) => {
                                                    if (label !== "" && label.includes("Locked")) {
                                                        return (
                                                            <Tooltip title={`Step ${index + 1} is Read Only.`}>
                                                                <Step key={index}>
                                                                    <StepButton
                                                                        // disabled
                                                                        onClick={() => this.handleNext(index)}
                                                                        StepIconComponent={LockIcon}
                                                                        className={classes.btnroot}
                                                                        focusVisibleClassName={classes.pointerr}>
                                                                        <StepLabel 
                                                                            classes={{
                                                                                labelContainer: classes.lessMargin,
                                                                            }}>
                                                                            {label.replace('Locked',"")}
                                                                        </StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                            </Tooltip>
                                                        );
                                                    } else {
                                                        return (
                                                            <Step key={label}>
                                                                <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                    {label}
                                                                </StepButton>
                                                            </Step>
                                                        );
                                                    }
                                                })}
                                          
                                        </Stepper>
                                    </Grid>
                                )}
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Typography>
                                            Please add all contacts associated with the permit and project. There is a button towards the bottom to
                                            add additional contacts such as Consultants, Contractors and additional property owners or
                                            representatives.
                                        </Typography>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                <b>Primary Property Owner (required)</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_first_name" label="First Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_last_name" label="Last Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_organization"
                                                            label="Organization / Company"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_mailing_address"
                                                            label="Mailing Address *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_mailing_address2" 
                                                            label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}  
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_city" label="City *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="owner_state"
                                                            label="State *"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_zip" label="Zip *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_phone"  usePhoneNumberFormat label="Phone *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_phone_ext" label="Phone Extension" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                <b>Consultant-Plan Preparer (Required)</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_first_name"
                                                            label="First Name *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_last_name" label="Last Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_organization"
                                                            label="Organization / Company *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_mailing_address"
                                                            label="Mailing Address *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_mailing_address2"
                                                            label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}  
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_city" label="City *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="secondary_state"
                                                            label="State *"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_zip" label="Zip *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_phone"  usePhoneNumberFormat label="Phone *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_phone_ext" label="Phone Extension" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_email" label="Email *" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Applicant (Required)</b><br></br>
                                                    Applicant Information: Please identify the business, institution, apartment complex, land development company, or other entity that is seeking the approval. Do NOT list here a contractor, surveyor, or other representative (see Representative Information Section).
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    {/* <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="applicant_type"
                                                            label="Applicant Role *"
                                                            options={MAKE_OPTIONS([
                                                                "Primary Property Owner", "Secondary Property Owner", "Consultant-Plan Preparer", 
                                                                "Contractor", "SWPPP Representative", "Permittee", "Trained Individual", "Other"
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid> */}
                                                    <Grid item xs={6}>
                                                        <Checkbox
                                                            eventHandle={checked => {
                                                                if (checked) {
                                                                    this.setState({ applicant_same_NO: false });
                                                                } else {
                                                                    this.setState({ applicant_same_NO: true });
                                                                }
                                                            }}
                                                            disabled={is_read_only }
                                                            label="Check if the same as the Primary Property Owner"
                                                            field="applicant_same"
                                                        />
                                                      
                                                    </Grid>
                                                    <Grid item xs={6}></Grid>
                                                    {/* <Grid item xs={6}>  
                                                        <Checkbox
                                                            eventHandle={checked => {
                                                                if (checked) {
                                                                    this.setState({ applicant_same_secondary_NO: false });
                                                                } else {
                                                                    this.setState({ applicant_same_secondary_NO: true });
                                                                }
                                                            }}
                                                            disabled={is_read_only || !applicant_same_NO}
                                                            label="Check if the same as Consultant Plan Preparer"
                                                            field="applicant_same_secondary"
                                                        />
                                                    </Grid> */}
                                                    {applicant_same_NO  && (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_first_name"
                                                                    label="First Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_last_name"
                                                                    label="Last Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_organization"
                                                                    label="Organization / Company "
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_mailing_address"
                                                                    label="Mailing Address *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="applicant_mailing_address2"
                                                                    label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}   
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_city" label="City *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    disabled={is_read_only}
                                                                    field="applicant_state"
                                                                    label="State *"
                                                                    options={states}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_zip" label="Zip *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_phone"  usePhoneNumberFormat label="Phone *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_phone_ext" label="Phone Extension" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="applicant_email" label="Email *" fullWidth />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    <b>Representative (Required)</b>
                                                    <br></br>
                                                    Representative Information (if other than the applicant): Complete this section if a contractor, surveyor, owner's representative, or other person will act on behalf of the applicant and all correspondence regarding the application should be directed to them.
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <Checkbox
                                                            eventHandle={checked => {
                                                                if (checked) {
                                                                    this.setState({ permittee_same_NO: false });
                                                                } else {
                                                                    this.setState({ permittee_same_NO: true });
                                                                }
                                                            }}
                                                            disabled={is_read_only || !permittee_same_secondary_NO}
                                                            label="Check if the same as the Primary Property Owner"
                                                            field="permittee_same"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>  
                                                        <Checkbox
                                                            eventHandle={checked => {
                                                                if (checked) {
                                                                    this.setState({ permittee_same_secondary_NO: false });
                                                                } else {
                                                                    this.setState({ permittee_same_secondary_NO: true });
                                                                }
                                                            }}
                                                            disabled={is_read_only || !permittee_same_NO}
                                                            label="Check if the same as Consultant Plan Preparer"
                                                            field="permittee_same_secondary"
                                                        />
                                                    </Grid>
                                                    {permittee_same_NO && permittee_same_secondary_NO && (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_first_name"
                                                                    label="First Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_last_name"
                                                                    label="Last Name *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_organization"
                                                                    label="Organization / Company "
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_mailing_address"
                                                                    label="Mailing Address *"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    disabled={is_read_only}
                                                                    field="permittee_mailing_address2"
                                                                    label={"Mailing Address 2 (i.e. Suite #, Apartment #, etc.)"}   
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_city" label="City *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    disabled={is_read_only}
                                                                    field="permittee_state"
                                                                    label="State *"
                                                                    options={states}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_zip" label="Zip *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_phone"   usePhoneNumberFormat label="Phone *" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_phone_ext" label="Phone Extension" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} field="permittee_email" label="Email *" fullWidth />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Typography variant="subtitle1">Additional Contacts</Typography>
                                        <Typography gutterBottom>
                                            If different from the primary owner, please provide contact information for another property owners
                                            subject to this application (secondary owners), a project representative (e.g., Consultant, Engineer,
                                            Arcitect, etc), and Erosion control Representative.
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({ contactDialogOpen: true, contactEditing: null, formKey: window.performance.now() })
                                            }
                                            disabled={is_read_only}
                                            variant="contained"
                                            color="primary">
                                            Add Contact
                                        </Button>
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataContacts}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                                {temp_contacts.map(n => {
                                                    if(n.when_submitted === 'initial' || !loaded_record ){
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Edit">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    contactDialogOpen: true,
                                                                                    contactEditing: n,
                                                                                    formKey: window.performance.now()
                                                                                })
                                                                            }
                                                                    
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            disabled={n.when_submitted === 'initial'? true:false}
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({ temp_contacts: temp_contacts.filter(tc => tc !== n) })
                                                                            }
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.type}</CustomTableCell>
                                                                <CustomTableCell>{n.first_name}</CustomTableCell>
                                                                <CustomTableCell>{n.last_name}</CustomTableCell>
                                                                <CustomTableCell>{n.mailing_address}</CustomTableCell>
                                                                <CustomTableCell>{n.phone}</CustomTableCell>
                                                                <CustomTableCell>{n.email}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    }else return null
                                                })}
                                                {temp_contacts.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataContacts.length} className={classes.centerAlign}>
                                                            No Additional Contacts Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 1 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                         <Grid container spacing={2}>
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <Typography variant="subtitle1" gutterBottom style={{marginBottom:-7, marginLeft:14}}>
                                                    <b>Instructions:</b> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{ marginLeft:16}}>
                                                    Step 1: Use the + or - buttons to zoom the map to your permit locations. Alternatively you can type an address or road intersection into the search box to zoom the map to the general area of your permit.
                                                </Typography>
                                                 <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                 Step 2: Click on the Mapping Tools button labeled "Add a New Permit Location". This will change your mouse cursor to a blue dot. Click on the map to place your location of your permit. For linear projects place it on the approximate mid-point of the project. For other projects place the point on the best place that represents the location of your project.
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 3: After you place the point on the map, the Project Location latitude/longitude fields will automatically populate. 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 4: If you need to modify the location of the point location on the map, Click Delete Project Location button and repeat Step 2. Otherwise click the Next button to proceed to the Project Details step. 
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                                                <BaseMapControlApplication
                                                    geometry={loaded_record && loaded_record.geometry?loaded_record.geometry:null}
                                                    disableSearch={loaded_record && loaded_record.geometry?true:false}
                                                    viewOnly
                                                    application={loaded_record}
                                                    setDialogLoading={this.setDialogLoading} 
                                                    setDrawingFeature={this.setDrawingFeature} 
                                                    layers={layers}
                                                    cityID={"11"}  
                                                /> 
                                                
                                                {dialogLoading && ( 
                                                    <CircularProgress
                                                        size={72}
                                                        style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                                                    /> 
                                               
                                                )} 
                                        </Grid> 
                                        </Grid>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Project Location
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="address_1" label="Address 1" fullWidth />
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="zip" label="Zip" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="location_description"
                                                            multiline
                                                            rows={4}
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Location Description *"
                                                                    helpText="Description of the location based on local landmarks (e.g., road, intersections). Include closest cross roads."
                                                                />
                                                            }
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="latitude" label="Latitude" fullWidth />
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="longitude" label="Longitude" fullWidth />
                                                    </Grid> 
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 2 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1">
                                                    Project Information and Details
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                    <Grid item xs={6}>
                                                        <TextField 
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Project Name *"
                                                                    helpText="Name as it appears on the construction plan and, when applicable, alternative names listed in the AKA field that may be associated with the project. The name must include the specific designations that are associated with the project and identified on the plans, including phases, sections, or other divisions."
                                                                />
                                                            } field="project_name" disabled={is_read_only} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="AKA (Also Known As)" field="aka" disabled={is_read_only} fullWidth />
                                                    </Grid>
                                                    {/* <Grid item xs={6}>
                                                        <TextField label="IDEM Permit Number" field="idem_number" disabled={is_read_only} fullWidth />
                                                    </Grid> */}
                                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                                        <br/>
                                                        <hr/> 
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="project_type"
                                                            label="Project Type *"
                                                            options={MAKE_OPTIONS([
                                                                "Commercial",
                                                                "Single Family Residential",
                                                                "Multi-family Residential",
                                                                "Industrial",
                                                                "Road",
                                                                "Government",
                                                                "Other",
                                                                "Disturbance/Fill Only"
                                                            ])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Project Narrative Description *"
                                                                    helpText="A brief description of the construction project, including a statement of the specific activity (clearing, grading, etc.) and which corresponds to the scope of the construction plan that was submitted for the project site."
                                                                />
                                                            }
                                                            field="project_description"
                                                            multiline
                                                            rows={4}
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            useNumberFormat3
                                               
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Project Acreage Total *"
                                                                    helpText="Total size of the project area including areas that will and will not be disturbed."
                                                                />
                                                            }
                                                            field="size"
                                                            unitsVisual="acres"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Proposed Total Disturbed Area (in acres) *"
                                                                    helpText="Land disturbance shall be calculated as a surface area that includes the outermost boundary of the area planned to be disturbed by construction, material storage, grading, grubbing, landscaping or other land disturbing activities. Total land disturbance associated with a project includes off-site work such as utility work, storage areas, stockpiles and borrow sites."
                                                                />
                                                            }
                                                            field="disturbed_area"
                                                            unitsVisual="acres"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Proposed Impervious Area *"
                                                            field="area_proposed_impervious"
                                                            unitsVisual="sq ft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Proposed Stormwater Management Facilities *"
                                                                    helpText="Practices that will be addressing water quality and water quantity long term after construction is completed. Example: wet pond, dry detention, hydrodynamic separator."
                                                                />
                                                            }
                                                            field="proposed_stormwater"
                                                            multiline
                                                            rows={3}
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                  
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion> 
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 3 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                            <Typography variant="subtitle1">
                                                Waterbody the Project immediately outlet to
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                <Grid item xs={6}>
                                                    <Select
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Immediate Receiving Waterbody *"
                                                                helpText="The waterbody the project immediately outlets to.  If connect to City Stormwater Conveyance indicate the waterbody the conveyance outlets to. "
                                                            />
                                                        }
                                                        field="im_receiving_waterbody"
                                                        disabled={is_read_only}
                                                        options={MAKE_OPTIONS([
                                                            "Haw Creek",
                                                            "Flatrock River",
                                                            "Clifty Creek",
                                                            "Driftwood River",
                                                            "Opossum Creek",
                                                            "Denois Creek",
                                                            "Wolf Creek",
                                                            "Sloan Branch",
                                                            "Joseph Anthony Drain",
                                                            "RIVER East Fork White River",
                                                            "CREEK East Fork White Creek",
                                                            "Unnamed Tributary"
                                                        ])}
                                                        fullWidth
                                                        eventHandle={(value)=>{
                                                            //for outstanding_waterbody trigger
                                                            if(value && (value === 'Flatrock River' || value === 'Driftwood River' || value === 'RIVER East Fork White River')){
                                                                this.formApi.setValue('im_outstanding_waterbody', 'Yes')
                                                            }else{
                                                                this.formApi.setValue('im_outstanding_waterbody', '')
                                                            }

                                                            //for 303_list trigger
                                                            if(value && (value === 'Flatrock River' || value === 'Haw Creek' || value === 'Joseph Anthony Drain' ||
                                                                value === 'Sloan Branch' || value === 'CREEK East Fork White Creek')){
                                                                this.formApi.setValue('im_303_list', 'Yes') 
                                                            }else{
                                                                this.formApi.setValue('im_303_list', '')
                                                            }

                                                            //for im what impairments trigger
                                                            if(value && (value === 'Flatrock River' || value === 'Haw Creek' || value === 'Joseph Anthony Drain' || value === 'Sloan Branch')){ 
                                                                this.formApi.setValue('im_what_impairments', 'Pathogens- E.Coli') 
                                                            }else if(value && value === 'CREEK East Fork White Creek'){
                                                                this.formApi.setValue('im_what_impairments', 'Biological Integrity')
                                                            }else{
                                                                this.formApi.setValue('im_what_impairments', ' ')
                                                            }

                                                             //for im tmdl trigger
                                                            if(value && (value === 'Flatrock River' || value === 'Haw Creek' || value === 'Joseph Anthony Drain' ||
                                                                value === 'Sloan Branch' || value === 'CREEK East Fork White Creek')){
                                                                this.formApi.setValue('im_tmdl', 'Yes') 
                                                            }else{
                                                                this.formApi.setValue('im_tmdl', '')
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="im_outstanding_waterbody"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is this listed as an outstanding waterbody? *"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="im_303_list"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is this listed on the 303d List *"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <Select
                                                        label={`If so, what are the impairments? ${this.formApi.getValue('im_303_list') === 'Yes' ? "*" : ""}`}
                                                        field="im_what_impairments"
                                                        options={MAKE_OPTIONS([
                                                            "Pathogens- E.Coli",
                                                            "Biological Integrity",
                                                            "Other"
                                                        ])}
                                                        // eventHandle={value=>{ 
                                                        //     this.formApi.setValue('im_what_impairments', value === " " ? "" : value)
                                                        // }}
                                                        disabled={is_read_only}
                                                        isMulti={false}
                                                        height="auto"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="im_tmdl"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is there a TMDL? *"
                                                    />
                                                </Grid>
                                    
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    </Grid>
                                )}
                                {(activeStep === 4 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                            <Typography variant="subtitle1">
                                                Waterbody immediately downstream of the Immediate Receiving Waterbody
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2} style={{ marginTop: 0 }}>
                                                <Grid item xs={6}>
                                                    <Select
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Receiving Waterbody *"
                                                                helpText="Waterbody immediately downstream of the Immediate Receiving Waterbody "
                                                            />
                                                        }
                                                        field="receiving_waterbody"
                                                        disabled={is_read_only}
                                                        options={MAKE_OPTIONS([
                                                            "Haw Creek",
                                                            "Flatrock River",
                                                            "Clifty Creek",
                                                            "Driftwood River",
                                                            "Opossum Creek",
                                                            "Denois Creek",
                                                            "Wolf Creek",
                                                            "Sloan Branch",
                                                            "Joseph Anthony Drain",
                                                            "RIVER East Fork White River",
                                                            "CREEK East Fork White Creek",
                                                            "Unnamed Tributary"
                                                        ])}
                                                        fullWidth
                                                        eventHandle={(value)=>{
                                                             //for outstanding_waterbody trigger
                                                             if(value && (value === 'Flatrock River' || value === 'Driftwood River' || value === 'RIVER East Fork White River')){
                                                                this.formApi.setValue('outstanding_waterbody', 'Yes')
                                                            }else{
                                                                this.formApi.setValue('outstanding_waterbody', '')
                                                            }

                                                            //for re303_list trigger
                                                            if(value && (value === 'Flatrock River' || value === 'Haw Creek' || value === 'Joseph Anthony Drain' ||
                                                                value === 'Sloan Branch' || value === 'CREEK East Fork White Creek')){
                                                                this.formApi.setValue('re_303_list', 'Yes') 
                                                            }else{
                                                                this.formApi.setValue('re_303_list', '')
                                                            }

                                                            //for what impairments trigger
                                                            if(value && (value === 'Flatrock River' || value === 'Haw Creek' || value === 'Joseph Anthony Drain' || value === 'Sloan Branch')){ 
                                                                this.formApi.setValue('what_impairments', 'Pathogens- E.Coli') 
                                                            }else if(value && value === 'CREEK East Fork White Creek'){
                                                                this.formApi.setValue('what_impairments', 'Biological Integrity')
                                                            }else{
                                                                this.formApi.setValue('what_impairments', ' ')
                                                            }

                                                             //for tmdl trigger
                                                            if(value && (value === 'Flatrock River' || value === 'Haw Creek' || value === 'Joseph Anthony Drain' ||
                                                                value === 'Sloan Branch' || value === 'CREEK East Fork White Creek')){
                                                                this.formApi.setValue('tmdl', 'Yes') 
                                                            }else{
                                                                this.formApi.setValue('tmdl', '')
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="outstanding_waterbody"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is this listed as an outstanding waterbody? *"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="re_303_list"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is this listed on the 303d List? *"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <Select
                                                        label={`If so, what are the impairments? ${this.formApi.getValue('re_303_list') === 'Yes' ? " *" : ""}`}
                                                        field="what_impairments"
                                                        options={MAKE_OPTIONS([
                                                            "Pathogens- E.Coli",
                                                            "Biological Integrity",
                                                            "Other"
                                                        ])}
                                                        // eventHandle={value=>{ 
                                                        //     this.formApi.setValue('what_impairments', value === " " ? "" : value)
                                                        // }}
                                                        disabled={is_read_only}
                                                        isMulti={true}
                                                        height="auto"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="tmdl"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is there a TMDL? *"
                                                    />
                                                </Grid>
                                     
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    </Grid>
                                )}
                                {(activeStep === 5 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                            <Typography variant="subtitle1">
                                                Sensitive and Critial Areas
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2} style={{ marginTop: 0 }}>
                                               
                                                
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="within_floodplain"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is the project within a floodplain (i.e. FEMA, Haw Creek Flood Hazard, Planning Ordinance)? * "
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="within_protection"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only} 
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Is the project within a wellhead protection area? *"
                                                                helpText="5 year wellfield time of travel area."
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="is_wetland"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is there a wetland on or adjacent to the project? *"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="conveyance"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is there a conveyance, natural or manmade, on or adjacent to the project (i.e. river, stream, gully, ditch, watercourse, unnamed tributary, etc.)? * "
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="groupc_d"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is project located within or adjacent to areas containing soils of hydrologic soil group C or D? * "
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="legal_drain"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is there a legal drain on or adjacent to the project? * "
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="contaminated"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is project located within or adjacent to areas that have been determined to be in a contaminated area? * "
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="impacted_drainage"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is project located within or adjacent to impacted drainage areas? *"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="erosion"
                                                        style={{marginTop:8}}
                                                        options={MAKE_OPTIONS(["Yes", "No"])}
                                                        alignment={true}
                                                        disabled={is_read_only}
                                                        label="Is the project located within or discharging to an area with known erosion, drainage, or flooding problems? * "
                                                    />
                                                </Grid> 
                                              
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    </Grid>
                                )}
                                {(activeStep === 6 || activeStep === "PRINT") && (<>
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1"><u>Attachments</u></Typography>
                                        <Typography gutterBottom>
                                            Please attached all electronic files associated with your applications. This could include but is not
                                            limited to the following:
                                            <ul> 
                                                <li>Signed Application - Signature Page</li>
                                                <li>Receipt for Application Fee</li> 
                                                <li>Plans- construction, civil, SWPPP, landscape, erosion & sediment control, etc.</li> 
                                                <li>Drainage Report- adequate outlet assessment, detention assessment, quantity management, quality management</li>
                                                <li>Operation and Maintenance Manual- for all Stormwater Management Facilities </li> 
                                                <li>IDEM Draft NOI</li>
                                                <li>Other</li> 
                                            </ul>
                                        </Typography> 
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        attachmentDialogOpen: true,
                                                        attachmentEditing: null,
                                                        attachmentFormKey: window.performance.now()
                                                    })
                                                }
                                                variant="contained"
                                                color="primary">
                                                Add Attachment
                                            </Button>
                                            {loaded_record && loaded_record.apprec && loaded_record.apprec.status === 'Saved - Pending Payment Verification' && (
                                                <Typography variant="subtitle1" style={{color:'red', paddingLeft:5}}>
                                                    To proceed click on the 'Add Attachment' button and attach at least the following two <u>file types</u>: <br></br> 
                                                    1.) Signed Application <br></br>
                                                    2.) Receipt for Application Fee   
                                                </Typography>
                                            )} 
                                        </Grid>
                                       
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments}
                                                order={attachmentOrder}
                                                orderBy={attachmentOrderBy}
                                                onRequestSort={this.handleRequestSort2}
                                            />
                                            <TableBody>
                                                {temp_attachments.map(n => {
                                                    if(!n.id)  
                                                    return (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {n.file.includes("https") && (
                                                                    <Tooltip title="Open Link">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                window.open(n.file);
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                temp_attachments: temp_attachments.filter(tc => tc !== n)
                                                                            })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{this.formatDate(n.date_uploaded)}</CustomTableCell>
                                                            <CustomTableCell>{n._type}</CustomTableCell>
                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                        </TableRow>
                                                    )
                                                    else return null
                                                })}
                                                {temp_attachments.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                            No Attachments Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                  
                                    {loaded_record && loaded_record.apprec && loaded_record.apprec.status && (
                                        <Grid item xs={12} xl={10}   classes={{ root: classes.root }}>
                                        <br/><br/>
                                        <Typography variant="subtitle1"><u>Additional Contacts</u></Typography>
                                            <Typography gutterBottom>
                                                NOTE-additional contacts should <u>only</u> include contacts that were NOT included in the 'Contact Info' step.
                                            </Typography>
                                            <Button
                                                onClick={() =>
                                                    this.setState({ contactDialogOpen: true, contactEditing: null, formKey: window.performance.now() })
                                                }
                                                variant="contained"
                                                color="primary">
                                                Additional Contact
                                            </Button>
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataContacts}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={this.handleRequestSort}
                                                />
                                                <TableBody>
                                                    {temp_contacts.map(n => {
                                                        if(!n.id || n.when_submitted === 'later'){ 
                                                            return (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        <Tooltip title="Edit">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        contactDialogOpen: true,
                                                                                        contactEditing: n,
                                                                                        formKey: window.performance.now()
                                                                                    })
                                                                                }
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                       
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    this.setState({ temp_contacts: temp_contacts.filter(tc => tc !== n) })
                                                                                }
                                                                                className={classes.deleteWidth}
                                                                                disabled={n && n.when_submitted === "later" ? true : false}>
                                                                                <DeleteIcon color="primary" />
                                                                                
                                                                            </Button>
                                                                        </Tooltip>
                                                                       
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.type}</CustomTableCell>
                                                                    <CustomTableCell>{n.first_name}</CustomTableCell>
                                                                    <CustomTableCell>{n.last_name}</CustomTableCell>
                                                                    <CustomTableCell>{n.mailing_address}</CustomTableCell>
                                                                    <CustomTableCell>{n.phone}</CustomTableCell>
                                                                    <CustomTableCell>{n.email}</CustomTableCell>
                                                                </TableRow>
                                                            );
                                                        } else return null
                                                    
                                                    })}
                                                    
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}
                                    
                                    <Grid item xs={12} xl={10}   classes={{ root: classes.root }}>
                                        <br/><br/>
                                    </Grid>
                                    {loaded_record && loaded_record.apprec && loaded_record.apprec.status && (
                                    <Grid item xs={12} xl={10}   classes={{ root: classes.root }}>
                                    <Accordion defaultExpanded>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            <b><u>Prior Attachments</u></b> 
                                            </Typography>
                                        </AccordionSummary>  
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataAttachments}
                                                    order={attachmentOrder}
                                                    orderBy={attachmentOrderBy}
                                                    onRequestSort={this.handleRequestSort2}
                                                />
                                                <TableBody>
                                                    {temp_attachments.map(n => {
                                                        if (n.id)
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                              
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{this.formatDate(n.date_uploaded)}</CustomTableCell>
                                                                <CustomTableCell>{n._type}</CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                        else return null
                                                    })}
                                                    {temp_attachments.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                                No Attachments Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Accordion>
                                    </Grid>
                                    )}

                                    </>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 7 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                        {/* <Typography variant="subtitle1" gutterBottom>
                                            Applicant Signature
                                        </Typography> */}
                                        <Typography variant="subtitle1" gutterBottom>
                                            <u>Applicant Acknowledgement</u>
                                        </Typography>
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature" />
                                                </td>
                                                <td>
                                                    <Typography gutterBottom style={{marginLeft:14}}>
                                                        I understand that the following practices are <u>not</u> allowed: 
                                                         <li>Straw bale filters are not acceptable for use.</li>
                                                         <li>Cationic polymers are not authorized for use in the State of Indiana. The use of anionic polymers on the project site for sediment control shall be in accordance with current State of Indiana standards and specifications and the use shall be approved by IDEM and the City.</li>
                                                         <li>Coconut mat or fabric on top of inlets shall not be the only inlet protection practice.</li>
                                                         <li>Rock-filled bags are not allowed.</li>
                                                       
                                                    </Typography>
                                                   
                                                </td>
                                            </tr>
                                        </table>
                                        
                                        {/* <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature2" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}} >
                                                        I agree to electronic communication such as but to limited to email correspondence of review statuses, review reports, inspection reports, fine and violation notifications. 
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature3" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                        Temporary downspout extenders must be used until the site is stabilized.
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature4" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                        If this application is not completed correctly with the appropriate information, the permit may be revoked.
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature5" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                    I have read the terms & conditions, reviewed the City Standards and agree that the individual lot implementation plan is consistent with the stormwater management permit, as approved by the City Engineering Department for the larger project.
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature6" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                        I am the legal owner of this property and/or I have the legal authority to sign for the owner.  
                                                    </Typography>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature7" />
                                                </td>
                                                <td>
                                                    <Typography style={{marginLeft:14}}>
                                                    "BY CHECKING I AGREE, I UNDERSTAND THAT, AS THE PERMITTEE, I AM LEGALLY ACCOUNTABLE TO ENSURE COMPLIANCE WITH THE TERMS AND CONDITIONS OF THE PERMIT. I UNDERSTAND THAT I AM NOT AUTHORIZED TO BEGIN THE PROJECT UNTIL I HAVE RECEIVED THE PERMIT, IF THE PROJECT IS MODIFIED, I WILL OBTAIN APPROVAL FROM THE CITY OF COLUMBUS BEFORE I CONTINUE WITH THE PROJECT. I AUTHORIZE THE CITY OF COLUMBUS, AND ITS AGENTS, EMPLOYEES, OFFICERS, AND CONTRACTORS, TO ENTER THE PROJECT SITE TO PERFORM ANY INSPECTIONS OF WORK AUTHORIZED BY THE PERMIT OR ANY APPLICABLE LAW." IF APPLICANT IS NOT THE PERMITTEE, I AGREE THAT I HAVE BEEN AUTHORIZED TO SUBMIT THE APPLICATION ON BEHALF OF THE PERMITTEE."
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </table> */}
                                      
                                    </Grid>
                                )}
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign} classes={{ root: classes.root }} style={{marginTop:10}}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={() => this.setState({ activeStep: activeStep - 1 }, window.scroll(0,0))}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                        {activeStep !== 6 && activeStep !== 7 && (
                                            <Button onClick={() => this.handleNext()} variant="contained" color="primary" style={{ marginLeft: 16 }}>Next</Button>
                                        )}
                                        {activeStep === 6 && is_read_only && (//read only happens after received status, so this step we will allow to submit again
                                            <Button onClick={() => this.formApi.submitForm()} variant="contained" color="primary" style={{ marginLeft: 16 }}>Submit Additional Documents</Button>
                                        )}
                                        {activeStep === 6 && !is_read_only && (
                                            <Button onClick={() => this.handleNext()} variant="contained" color="primary" style={{ marginLeft: 16 }}>Next</Button>
                                        )}
                                        {activeStep === 7 && !is_read_only && (
                                            <Button onClick={() => this.handleNext()} variant="contained" color="primary" style={{ marginLeft: 16 }}>Submit Permit Application</Button>
                                        )}
                                       
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog open={contactDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Additional Contact
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ contactDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={formKey}
                                defaultValues={contactEditing}
                                validateError={this.errorValidatorContact}
                                onSubmit={values => this.addContact(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Select
                                                    field="type"
                                                    label="Role *"
                                                    options={MAKE_OPTIONS([ 
                                                        "Secondary Property Owner", 
                                                        "Contractor",
                                                        "SWPPP Representative", 
                                                        "Trained Individual",
                                                        "Other"
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}/>
                                                {/* <TextField field="when_submitted" label="" fullWidth style={{display:'none'}} />
                                            </Grid> */}
                                            <Grid item xs={6}>
                                                <TextField field="first_name" label="First Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="last_name" label="Last Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="organization" label="Organization / Company" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address" label="Mailing Address *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address2"  
                                                    label={"Mailing Address 2 (Suite #, Apartment #, etc.)"} 
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="city" label="City *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select field="state" label="State *" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="zip" label="Zip *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="phone" label="Phone *" fullWidth  usePhoneNumberFormat />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="phone_ext" label="Phone Extension" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="email" label="Email *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}></Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ contactDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary" 
                                                    disabled={contactEditing && (contactEditing.when_submitted === "later" || contactEditing.when_submitted === "initial") ? true : false}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={values => this.addAttachment(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type *"
                                                    options={MAKE_OPTIONS([ 
                                                        "Signed Application",
                                                        "Receipt for Application Fee",  
                                                        "Construction Plans/SWPPP/Site Plan",
                                                        "Drainage/Water Quality Calculations",
                                                        "Operation & Maintenance Manual",
                                                        "DRAFT IDEM NOI",
                                                        "Receipt for Review Fee",
                                                        "Estimate Guarantee",
                                                        "Guarantee",
                                                        "IDEM NOS",
                                                        "Submittal-Response Letter",
                                                        "Trained Individual Documentation",
                                                        "General",

                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" multiline rows={4} label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Make sure to <u>submit</u> application after all attachments have been added
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

ColumbusBlueApp = connect(
    (state, ownProps) => ({
        // application: getApplication(state, ownProps),
        authState: state.auth // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...ColumbusBlue.actions,
        ...ColumbusBlueContact.actions,
        ...ColumbusBlueAttachment.actions
    }
)(ColumbusBlueApp);

export default withStyles(styles)(withRouter(ColumbusBlueApp));
