import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import JssProvider from "react-jss/lib/JssProvider";
import { setDefaultOptions } from "esri-loader";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider as ReduxProvider } from "react-redux";
import { createReducer } from "redux-orm";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import localForage from "localforage";

import { MuiThemeProvider } from "@material-ui/core/styles/";
import { createGenerateClassName } from "@material-ui/styles/";

import HomePage from "./components/home/HomePage";
import MapPage from "./components/home/Map";
import MapGlobal from "./components/home/MapGlobal";

import PasswordPage from "./components/auth/PasswordPage";
import PasswordRecoverPage from "./components/auth/PasswordRecoverPage";
import UpdatePage from "./components/auth/UpdatePage";
import KeycloakRedirect from "./components/auth/KeycloakRedirect";
import DashboardNotConfig from "./components/home/DashboardNotConfig";

import AdmCreate from "./components/adm/Create";
import AdmManage from "./components/adm/Manage";
import AdmEditUser from "./components/adm/EditUser";
import ConfigLetterTemplates from "./components/config/LetterTemplates";
import ConfigMapSettings from "./components/config/MapSettings";
import ConfigPageConfig from "./components/config/PageConfig";
import ConfigPageDetail from "./components/config/PageDetail";
import ConfigFormDetail from "./components/config/FormDetail";
import ModuleNotes from "./components/config/ModuleNotes";
import CustomReports from "./components/customreports/Home";
import ApplicationsHome from "./components/applications/Home";
import ApplicationRecords from "./components/applications/Records";
import NineMilePermit from "./components/applications/templates/NineMilePermit";
import NineMileGrant from "./components/applications/templates/NineMileGrant";
import RpbcwdStewardshipGrant from "./components/applications/templates/RpbcwdStewardshipGrant";
import BRRWD from "./components/applications/templates/BRRWD";
import Roseau from "./components/applications/templates/Roseau";
import CLWD from "./components/applications/templates/CLWD";
import Sandhill from "./components/applications/templates/SandhillPermit";
import WildRice from "./components/applications/templates/WildRicePermit";
import RedLakePermit from "./components/applications/templates/RedLakePermit"
import SRWD from "./components/applications/templates/Srwd";
import RPBCWD from "./components/applications/templates/Rpbcwd";
import CCWDPermit from "./components/applications/templates/CCWDPermit";
import ColumbusBlue from "./components/applications/templates/ColumbusBlue";
import ColumbusGreen from "./components/applications/templates/ColumbusGreen";
import ColumbusResidentialApp from "./components/applications/templates/ColumbusResidential";
import ColumbusHome from "./components/applications/templates/ColumbusHome";
import CrwdStewardship from "./components/applications/templates/CrwdStewardship";
import CrwdStewardshipGrant from "./components/applications/templates/CrwdStewardshipGrant";
import CrwdPermit from "./components/applications/templates/CrwdPermit";
import CrwdPartner from "./components/applications/templates/CrwdPartner";
import Contacts from "./components/config/Contacts";
import ExpansionPanelFormRecord from "./components/page/form/ExpansionPanelFormRecord";
import MapRecord from "./components/page/map/MapRecord";
import AllRecordsTableContainer from "./components/page/landing/AllRecordsTableContainer";
import AdvancedRecordTable from "./components/page/advancedSearch/AdvancedRecordTable";
import ErrorBoundary from "./components/common/ErrorBoundary";
import registerServiceWorker from "./registerServiceWorker";


import navReducer from "./components/common/reducers";
import { storeReady } from "./components/common/actions";
import authReducer from "./components/auth/reducers";
import orm, { reloadAll, syncReducer } from "./components/common/orm";
import WarningDialogWrapper from "./components/common/WarningDialogWrapper";
import { theme } from "./api/constants";
import CreateAdvancedSearches from "./components/page/advancedSearch/CreateAdvancedSearches";
import ChildTables from "./components/page/form/ChildTables";
import CreateRepeatableSection from "./components/page/form/CreateRepeatableSection";
import ContactConfig from "./components/config/ContactConfig";
import DashboardConfigured from "./components/home/DashboardConfigured";
import DataFeed from "./components/home/DataFeed";
// The only important thing on this page is the hashrouter near the bottom

// Default theme styles to be used throughout the application
// See https://cssinjs.org for more advanced use cases (media queries, targeting child elements)
//test
//esri
setDefaultOptions({ version: "4.27" });

const generateClassName = createGenerateClassName({
    productionPrefix: "c",
});

const logger = createLogger();

//add new reducers to this method to combine them into one reducer for the store
const reducer = combineReducers({
    auth: authReducer,
    orm: createReducer(orm),
    sync: syncReducer, //is uploadbar that occurs when something is submitted.
    nav: navReducer,
});

// used within action.js and reducers.js which keep track of navigation state
//this is the initial state of 'redux' for when the store is all wired up initially
//under createStore below
const initialState = {
    nav: {
        navigationOpen: true,
        pagesOpen: true,
        reportsOpen: false,
        configOpen: false,
        sw: { checking: true },
        map: { lat: null, lng: null, zoom: null },
        tabref: null, //section header reference, used to recenter underline
    },
};

const middleware = process.env.NODE_ENV === "production" ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//createStore takes 3 props, reducer, initialState of app, and enhancers
const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
        middleware,
        offline({
            ...offlineConfig,
            retry: (action, retries) => null, // don't bother retrying
            persistOptions: {
                storage: localForage,
                serialize: false,
            },
            persistAutoRehydrate: () =>
                offlineConfig.persistAutoRehydrate({
                    stateReconciler: (state, inboundState) => {
                        // Don't carry over pending from previous session
                        // Loading icon becomes stuck
                        if (inboundState.auth && inboundState.auth.pending) {
                            inboundState.auth.pending = null;
                        }
                        // Don't wipe out sw notification if it happened during init
                        if (state.nav && state.nav.sw) {
                            inboundState.nav = {
                                ...inboundState.nav,
                                sw: state.nav.sw,
                            };
                        }
                        inboundState.offline = {
                            ...state.offline,
                            ...inboundState.offline,
                            online: state.offline.online,
                            netInfo: state.offline.netInfo,
                            busy: state.offline.busy,
                        };

                        // When registering a brand new model
                        // the new model is not present in inboundState.orm
                        // but it is in state.orm.
                        // Add the empty orm models through state.orm so the new model
                        // is present but overwrite any existing models through inboundState.orm
                        inboundState.orm = {
                            ...state.orm,
                            ...inboundState.orm,
                        };

                        inboundState.sync = state.sync;

                        return inboundState;
                    },
                }),
            persistCallback: () => {
                store.dispatch(storeReady());
                store.dispatch(reloadAll(true));
            },
        })
    )
);

// Reusuable popup
const getConfirmation = (message, callback) => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <WarningDialogWrapper message={message} callback={callback} />
        </MuiThemeProvider>,
        document.getElementById("pp")
    );
};

/****************************************************************************************************************************************
    Every time you create a new page/component you will have to add it here as a route
    Use :id as a reference to the object's specific database id (ex: <Route path="/page/:id/:secID" component={PageDetailsWSection} /> 
    where :id = the pageID and :secID = the section ID these should be called out in the COMPONENT for example a 'button' would have these additional fields added to its 
    attributes "component={Link}  to={"/page/" + page + "/" + sectionID}" . Refer to ./page/ExpansionPanel.js for more info

    Make sure the long Note the order of the routes matter. er url is above the shorter user
    Ex: If you have two routes: path="/mypage" followed by path="/mypage/:id" the second route will never be hit because both urls will match the first route
    using 'exact' also forces it to be exactly that URL
*/
ReactDOM.render(
    <ReduxProvider store={store}>
        {/* <ErrorBoundary store={store}> */}
        <JssProvider generateClassName={generateClassName}>
            <MuiThemeProvider theme={theme}>
                <ErrorBoundary store={store}>  
                    <HashRouter getUserConfirmation={getConfirmation}>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/user/:username" component={KeycloakRedirect} />
                            <Route path="/password/reset" component={PasswordPage} />
                            <Route path="/reset/confirm/:uid/:token" component={PasswordRecoverPage} />
                            <Route path="/updateuser/:id" component={UpdatePage} />
                            <Route exact path="/dashboard" component={DashboardNotConfig} />
                            <Route exact path="/dashboard/:id" component={DashboardConfigured} />
                            <Route exact path="/datafeed/:id" component={DataFeed} />
                            <Route exact strict path="/map/:userId" component={MapGlobal} />
                            <Route exact path="/map/:pageId/section/:sectionId/advancedsearch/:advId" component={MapPage} />
                            <Route path="/module/notes/:id" component={ModuleNotes} />
                            <Route path="/adm/create" component={AdmCreate} />
                            <Route path="/adm/manage" component={AdmManage} />
                            <Route path="/adm/edit/:id" component={AdmEditUser} />
                            <Route path="/config/letter" component={ConfigLetterTemplates} />
                            <Route path="/config/map" component={ConfigMapSettings} />
                            <Route path="/config/page" component={ConfigPageConfig} />
                            <Route exact strict path="/page/:id/advancedsearch" component={CreateAdvancedSearches} />
                            <Route exact strict path="/page/:id/advancedsearch/:advID" component={AdvancedRecordTable} />
                            <Route
                                exact
                                strict
                                path="/page/:pageId/parentrecord/:parentRecordId/recordsection/:recordSectionId/map"
                                component={MapRecord}
                            />
                            <Route exact strict path="/page/:pageId/parentrecord/:parentRecordId/section/:sectionId/form" component={ChildTables} />
                            <Route
                                exact
                                strict //main parent record route
                                path="/page/:pageId/parentrecord/:parentRecordId/section/:sectionId/recordsection/:recordSectionId/form"
                                component={ExpansionPanelFormRecord}
                            />
                            <Route
                                exact
                                strict
                                path="/page/:pageId/parentrecord/:parentRecordId/section/:sectionId/subform"
                                component={CreateRepeatableSection}
                            />
                            <Route path="/contacts/config" component={ContactConfig} />
                            <Route path="/contacts" component={Contacts} />
                            {/* The section table with records from all parents */}
                            <Route exact strict path="/page/:id/:sectionId/table" component={AllRecordsTableContainer} />
                            {/* Page Configuration */}
                            <Route exact strict path="/page/:id/config" component={ConfigPageDetail} />
                            {/* Created formId route  */}
                            <Route path="/form/:section/:formId" component={ConfigFormDetail} />
                            {/* Applications */}
                            <Route path="/applications/redlake/permit/:hash/:print" component={RedLakePermit} /> {/* Printing */}
                            <Route path="/applications/redlake/permit/:hash" component={RedLakePermit} /> {/* Editing */}
                            <Route path="/applications/redlake/permit" component={RedLakePermit} /> {/* New */}
                            <Route path="/applications/wildrice/permit/:hash/:print" component={WildRice} /> {/* Printing */}
                            <Route path="/applications/wildrice/permit/:hash" component={WildRice} /> {/* Editing */}
                            <Route path="/applications/wildrice/permit" component={WildRice} /> {/* New */}
                            <Route path="/applications/rpbcwd/stewardshipgrant/:hash/:print" component={RpbcwdStewardshipGrant} /> {/* Printing */}
                            <Route path="/applications/rpbcwd/stewardshipgrant/:hash" component={RpbcwdStewardshipGrant} /> {/* Editing */}
                            <Route path="/applications/rpbcwd/stewardshipgrant" component={RpbcwdStewardshipGrant} /> {/* New */}
                            <Route path="/applications/ninemile/grant/:hash/:print" component={NineMileGrant} /> {/* Printing */}
                            <Route path="/applications/ninemile/grant/:hash" component={NineMileGrant} /> {/* Editing */}
                            <Route path="/applications/ninemile/grant" component={NineMileGrant} /> {/* New */}
                            <Route path="/applications/ninemile/permit/:hash/:print" component={NineMilePermit} /> {/* Printing */}
                            <Route path="/applications/ninemile/permit/:hash" component={NineMilePermit} /> {/* Editing */}
                            <Route path="/applications/ninemile/permit" component={NineMilePermit} /> {/* New */}
                            <Route path="/applications/brrwd/permit/:hash/:print" component={BRRWD} /> {/* Printing */}
                            <Route path="/applications/brrwd/permit/:hash" component={BRRWD} /> {/* Editing */}
                            <Route path="/applications/brrwd/permit" component={BRRWD} /> {/* New */}
                            <Route path="/applications/roseau/permit/:hash/:print" component={Roseau} /> {/* Printing */}
                            <Route path="/applications/roseau/permit/:hash" component={Roseau} /> {/* Editing */}
                            <Route path="/applications/roseau/permit" component={Roseau} /> {/* New */}
                            <Route path="/applications/clwd/permit/:hash/:print" component={CLWD} /> {/* Printing */}
                            <Route path="/applications/clwd/permit/:hash" component={CLWD} /> {/* Editing */}
                            <Route path="/applications/clwd/permit" component={CLWD} /> {/* New */}
                            <Route path="/applications/sandhill/permit/:hash/:print" component={Sandhill} /> {/* Printing */}
                            <Route path="/applications/sandhill/permit/:hash" component={Sandhill} /> {/* Editing */}
                            <Route path="/applications/sandhill/permit" component={Sandhill} /> {/* New */}
                            <Route path="/applications/srwd/permit/:hash/:print" component={SRWD} /> {/* Printing */}
                            <Route path="/applications/srwd/permit/:hash" component={SRWD} /> {/* Editing */}
                            <Route path="/applications/srwd/permit" component={SRWD} /> {/* New */}
                            <Route path="/applications/rpbcwd/permit/:hash/:print" component={RPBCWD} /> {/* Printing */}
                            <Route path="/applications/rpbcwd/permit/:hash" component={RPBCWD} /> {/* Editing */}
                            <Route path="/applications/rpbcwd/permit" component={RPBCWD} /> {/* New */}
                            <Route path="/applications/ccwd/permit/:hash/:print" component={CCWDPermit} /> {/* Printing */}
                            <Route path="/applications/ccwd/permit/:hash" component={CCWDPermit} /> {/* Editing */}
                            <Route path="/applications/ccwd/permit" component={CCWDPermit} /> {/* New */} 
                            <Route path="/applications/columbus/application" component={ColumbusHome} /> {/* Home page to determine which app to open */}
                            <Route path="/applications/columbus/permit/blue/:hash/:print" component={ColumbusBlue} /> {/* Printing */}
                            <Route path="/applications/columbus/permit/blue/:hash" component={ColumbusBlue} /> {/* Editing */}
                            <Route path="/applications/columbus/permit/blue" component={ColumbusBlue} /> {/* New */}
                            <Route path="/applications/columbus/permit/green/:hash/:print" component={ColumbusGreen} /> {/* Printing */}
                            <Route path="/applications/columbus/permit/green/:hash" component={ColumbusGreen} /> {/* Editing */}
                            <Route path="/applications/columbus/permit/green" component={ColumbusGreen} /> {/* New */}
                            <Route path="/applications/columbus/permit/single/:hash/:print" component={ColumbusResidentialApp} /> {/* Printing */}
                            <Route path="/applications/columbus/permit/single/:hash" component={ColumbusResidentialApp} /> {/* Editing */}
                            <Route path="/applications/columbus/permit/single" component={ColumbusResidentialApp} /> {/* New */} 
                            <Route path="/applications/crwd/stewardship/:hash/:print" component={CrwdStewardship} /> {/* Printing */}
                            <Route path="/applications/crwd/stewardship/:hash" component={CrwdStewardship} /> {/* Editing */}
                            <Route path="/applications/crwd/stewardship" component={CrwdStewardship} /> {/* New */}
                            <Route path="/applications/crwd/stewardshipgrant/:hash/:print" component={CrwdStewardshipGrant} />
                            <Route path="/applications/crwd/stewardshipgrant/:hash" component={CrwdStewardshipGrant} /> {/* Editing */}
                            <Route path="/applications/crwd/stewardshipgrant" component={CrwdStewardshipGrant} /> {/* New */}
                            <Route path="/applications/crwd/permit/:hash/:print" component={CrwdPermit} /> {/* Printing */}
                            <Route path="/applications/crwd/permit/:hash" component={CrwdPermit} /> {/* Editing */}
                            <Route path="/applications/crwd/permit" component={CrwdPermit} /> {/* New */}
                            <Route path="/applications/crwd/partner/:hash/:print" component={CrwdPartner} /> {/* Printing */}
                            <Route path="/applications/crwd/partner/:hash" component={CrwdPartner} /> {/* Editing */}
                            <Route path="/applications/crwd/partner" component={CrwdPartner} /> {/* New */}
                            <Route path="/applications/:id" component={ApplicationRecords} /> {/* Global */}
                            <Route path="/applications" component={ApplicationsHome} /> {/* Global */}
                            <Route path="/customreports/:id" component={CustomReports} /> {/*Actual Report*/}
                            <Route path="/customreports" component={CustomReports} /> {/*MainTable */}
                            <Redirect to="/" />;
                        </Switch>
                    </HashRouter>
                </ErrorBoundary>
            </MuiThemeProvider>
        </JssProvider>
        {/* </ErrorBoundary> */}
    </ReduxProvider>,
    document.getElementById("root")
);

// The service worker is responsible for determining when an application was updated and transferring the new code over to the user's browser
registerServiceWorker(store.dispatch);
